import Toaster from '../../../src/utilities/toaster'
import Head from 'next/head'
import Script from 'next/script'
import React from 'react'
import GenericLoginButton from './genericLoginButton'
import { useApis } from '../../contexts/api-context'
import useProcessSuccessfulLogin from '../../hooks/useProcessSuccessfulLogin'
import { useRouter } from 'next/router'

const AppleLoginButton: React.FC<{ children?: React.ReactNode; emphasizeSocials?: boolean }> = ({
  children,
  emphasizeSocials = false,
}) => {
  const { next } = useApis()
  const processLogin = useProcessSuccessfulLogin()
  const router = useRouter()

  const { query } = router
  const [isLoading, setIsLoading] = React.useState(false)

  const { therapistId, diagnosis, redirectUrl } = router.query as Record<string, any>

  const handleSignInWithApple = async () => {
    if (!window.AppleID) {
      console.error(new Error('Error loading apple script'))
      return
    }
    const res = await window.AppleID.auth.signIn()
    setIsLoading(true)
    const { authorization, user } = res

    try {
      const regex = /companyEmployee\[email\]=([^&]+)/
      const companyEmail = decodeURIComponent(router.asPath).match(regex)?.[1]
      console.log(user, companyEmail, 'user, email')

      const response = await next.loginApple({
        authorization,
        user,
        ...(query['companyEmployee[token]']
          ? {
              companyEmployee: {
                slug: query['companyEmployee[slug]'],
                companyEmail,
                metaWorkplaceMemberId: query['companyEmployee[metaWorkplaceMemberId]'],
                token: query['companyEmployee[token]'],
                code: query['companyEmployee[code]'],
              },
            }
          : {}),
        doNotCreateNewUser: router.route === '/login' ? true : undefined,
      })
      processLogin({
        response,
        redirectUrl,
        email: companyEmail,
        therapistId: Number.parseInt(therapistId, 10),
        diagnosis,
        sendEvent: response.status !== 201,
      })
    } catch (err: any) {
      const response = err.response?.data
      setIsLoading(false)

      if (response.type === 'NOT_FOUND') {
        Toaster.error('errors.authorization.notFound')
        router.replace({
          pathname: '/login',
          query: {
            ...router.query,
            socialUser: response.socialUser,
            kind: 'apple',
            user: encodeURIComponent(JSON.stringify(user)),
          },
        })
      } else if (response.type === 'NO_SUCH_VERIFICATION') {
        Toaster.error('errors.authorization.noSuchVerification')
      } else {
        Toaster.error('errors.defaultError')
        console.log('apple login error', err)
      }
    }
  }
  return (
    <>
      <Head>
        <meta name="appleid-signin-client-id" content="com.hedepy.web" />
        <meta name="appleid-signin-scope" content="name email" />
        <meta name="appleid-signin-redirect-uri" content={`${process.env.NEXT_PUBLIC_FE_URL}/callback/apple`} />
        {/* <meta name="appleid-signin-state" content="[STATE]" /> */}
        {/* <meta name="appleid-signin-nonce" content="[NONCE]" /> */}
        <meta name="appleid-signin-use-popup" content="true" />
      </Head>
      <GenericLoginButton
        icon={
          <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.967 13.2064C15.5451 14.1434 15.3418 14.5622 14.7992 15.3891C14.0395 16.5447 12.968 17.9876 11.6443 17.9968C10.4658 18.0075 10.1617 17.228 8.56131 17.2417C6.96248 17.2494 6.62926 18.0121 5.44925 17.9999C4.12402 17.9876 3.11061 16.6884 2.35093 15.5344C0.226291 12.2985 0.00159928 8.50469 1.3146 6.48704C2.24394 5.05635 3.71285 4.21566 5.0931 4.21566C6.49934 4.21566 7.38282 4.98756 8.5445 4.98756C9.67255 4.98756 10.3589 4.21413 11.9852 4.21413C13.2141 4.21413 14.5134 4.88362 15.4427 6.03918C12.404 7.70527 12.8977 12.0447 15.967 13.2064ZM10.7502 2.92253C11.3417 2.16286 11.7911 1.09136 11.6275 0C10.663 0.0657265 9.53498 0.68172 8.87619 1.47961C8.27854 2.20718 7.7833 3.28632 7.97589 4.3303C9.02904 4.36393 10.1189 3.7357 10.7502 2.92253Z"
              fill="black"
            />
          </svg>
        }
        onClick={handleSignInWithApple}
        isLoading={isLoading}
        emphasizeSocials={emphasizeSocials}
      >
        {children}
      </GenericLoginButton>
      <Script
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        strategy="afterInteractive"
      />
    </>
  )
}

export default AppleLoginButton
