import React from 'react'
import Markdown from 'markdown-to-jsx'
import Icon from './icons/Icon'
import { be } from '../utilities/bliss'
import { supportNumber } from '../utilities/tel'
import { RadixButtonLink } from '../rebrand/buttonRadix'
import { getTranslate } from '@/src/utilities/i18n'

type PhoneSupportTypes = {
  icon?: string
  className?: string
  modifiers?: string
  help?: boolean
}

const PhoneSupport: React.FC<PhoneSupportTypes> = ({ modifiers = '', className = '', icon = '', help }) => {
  const { t } = getTranslate()
  return (
    <div className={be('phone', 'support', [icon ? 'icon' : '', modifiers || ''], className)}>
      {icon && <Icon icon={icon} />}
      <Markdown className={be('phone', 'text')}>{t(`general.support.${help ? 'help' : 'main'}`)}</Markdown>
      <RadixButtonLink href={`tel:${supportNumber}`} variant="inline">
        <span>
          <strong>&nbsp;{supportNumber}</strong>
        </span>
      </RadixButtonLink>
    </div>
  )
}

export default PhoneSupport
