import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import UserActions from '../../redux/UserRedux'
import Loader from '../Loader'
import { LOCALE } from '../../constants/locale.constants'

type Props = { isDiagnosis?: boolean; emphasizeSocials?: boolean; children?: React.ReactNode }

const GoogleLoginButton: React.FC<Props> = ({ emphasizeSocials = false }) => {
  const dispatch = useDispatch()
  const router = useRouter()

  const { query } = router
  const [isPending, setIsPending] = useState(false)
  const [buttonWidth, setButtonWidth] = useState(400)

  const { therapistId, diagnosis, redirectUrl } = router.query as Record<string, any>

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.id = 'google-client-script'
    document.querySelector('body')?.appendChild(script)

    const currentWidth = Math.min(window?.innerWidth - 60, 400)
    setButtonWidth(currentWidth)

    window.handleCredentialResponse = (googleUser: any) => {
      setIsPending(true)
      const regex = /companyEmployee\[email\]=([^&]+)/
      const companyEmail = decodeURIComponent(router.asPath).match(regex)?.[1]

      dispatch(
        UserActions.loginRequestGoogle(
          googleUser.credential,
          Number.parseInt(therapistId, 10),
          diagnosis,
          redirectUrl,
          router.route === '/login' ? true : undefined,
          ...(query['companyEmployee[token]']
            ? [
                {
                  slug: query['companyEmployee[slug]'],
                  companyEmail,
                  metaWorkplaceMemberId: query['companyEmployee[metaWorkplaceMemberId]'],
                  token: query['companyEmployee[token]'],
                  code: query['companyEmployee[code]'],
                },
              ]
            : []),
        ),
      )
    }

    return () => {
      // Cleanup function that runs when component unmounts
      window?.google?.accounts.id.cancel()
      document.getElementById('google-client-script')?.remove()
    }
  }, [router])

  /* Google Buttons doc https://developers.google.com/identity/gsi/web/guides/personalized-button */
  return (
    <>
      <Loader color="light-gray" visible={isPending} />

      <div
        id="g_id_onload"
        data-client_id={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        data-context="signin"
        data-ux_mode="popup"
        data-callback="handleCredentialResponse"
        data-itp_support="true"
      />

      {emphasizeSocials ? (
        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="continue_with"
          data-width={buttonWidth}
          data-size="large"
          data-logo_alignment="center"
          data-locale={LOCALE}
          data-use_fedcm_for_prompt={true}
        />
      ) : (
        <div
          className="g_id_signin"
          data-type="standard"
          data-shape="rectangular"
          data-theme="outline"
          data-text="continue_with"
          data-size="large"
          data-logo_alignment="center"
          data-locale={LOCALE}
          data-use_fedcm_for_prompt={true}
        />
      )}
    </>
  )
}

export default GoogleLoginButton
