import React from 'react'
import { be } from '../utilities/bliss'
import { getTranslate } from '@/src/utilities/i18n'
import { RadixButtonLink } from '../rebrand/buttonRadix'
import HedepyLogo from './HedepyLogo'
import Link from 'next/link'
import { isNewBrandingActive } from '../rebrand/utilities'

type Props = {
  backToIntroduction?: boolean
}

const SignUpHeader: React.FC<Props> = ({ backToIntroduction = false }) => {
  const { t } = getTranslate()
  const wordpressUrl = isNewBrandingActive ? '/' : process.env.NEXT_PUBLIC_WORDPRESS_WEB

  return (
    <div className={be('signup', 'header')}>
      <Link href={wordpressUrl}>
        <HedepyLogo className="min-w-[140px] tablet:min-w-[200px]" />
      </Link>
      {backToIntroduction && (
        <RadixButtonLink href={wordpressUrl} size="small" variant="light">
          {t('component.button.backToIntroduction')}
        </RadixButtonLink>
      )}
    </div>
  )
}

export default SignUpHeader
