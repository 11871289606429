import React from 'react'
import { getTranslate } from '../../utilities/i18n'
import TextDivider from '../Divider'
import FacebookLoginButton from './facebookButton'
import GoogleLoginButton from './googleLoginButton'
import AppleLoginButton from './appleButton'
import useFlags from '../../hooks/useFlags'
import Markdown from 'markdown-to-jsx'

type Props = {
  readonly buttonText: string
  readonly isDiagnosis?: boolean
  readonly emphasizeSocials?: boolean
}

const LoginButtons: React.FC<Props> = ({ buttonText, isDiagnosis, emphasizeSocials = false }) => {
  const { t } = getTranslate()
  const { showAppleLogin } = useFlags()

  const displayFbLogin = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID
  const displayGoogleLogin = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID
  const displayAppleButton = showAppleLogin
  const displayLoginButtons = displayFbLogin || displayGoogleLogin

  if (!displayLoginButtons) return null

  return (
    <div className="mb-2 w-full">
      <div className="mb-6">
        <div className={`-m-1 flex ${emphasizeSocials ? 'flex-col' : 'flex-wrap'} items-center justify-center`}>
          {displayGoogleLogin && (
            <div className="p-1">
              <GoogleLoginButton isDiagnosis={isDiagnosis} emphasizeSocials={emphasizeSocials}>
                {emphasizeSocials ? t('registration.continueWith.google') : buttonText}
              </GoogleLoginButton>
            </div>
          )}
          {displayFbLogin && (
            <div className={`${emphasizeSocials ? 'w-full' : ''} p-1`}>
              <FacebookLoginButton isDiagnosis={isDiagnosis} emphasizeSocials={emphasizeSocials}>
                {' '}
                {emphasizeSocials ? t('registration.continueWith.facebook') : buttonText}
              </FacebookLoginButton>
            </div>
          )}
          {displayAppleButton && (
            <div className={`${emphasizeSocials ? 'w-full' : ''} p-1`}>
              <AppleLoginButton emphasizeSocials={emphasizeSocials}>
                {emphasizeSocials ? t('registration.continueWith.apple') : buttonText}
              </AppleLoginButton>
            </div>
          )}
        </div>
        <p className="my-4 text-center text-sm text-text-color-light">
          <Markdown
            options={{
              overrides: {
                a: (props) => {
                  return <a target="_blank" rel="noopener noreferrer" {...props} />
                },
              },
            }}
          >
            {t(`registration.gdpr`)}
          </Markdown>
        </p>
      </div>
      <TextDivider>
        <div className="capitalize">{emphasizeSocials ? t('general.or') : t('login.orEmail')}</div>
      </TextDivider>
    </div>
  )
}

export default LoginButtons
