import React from 'react'

const Line: React.FC<Props> = ({ borderColorClass = 'border-text-color-light' }) => {
  return <div className={`h-[1px] flex-grow border-0 border-t-[2px] border-solid ${borderColorClass}`} />
}

type Props = { borderColorClass?: string; padded?: boolean }

const TextDivider: React.FC<Props & { children: React.ReactNode }> = ({ children, borderColorClass, padded }) => {
  return (
    <div className={`flex items-center ${padded ? 'px-4' : ''}`}>
      <Line {...{ borderColorClass }} />
      <div className="px-6">{children}</div>
      <Line {...{ borderColorClass }} />
    </div>
  )
}

export default TextDivider
