import React from 'react'
import Icon from './icons/Icon'
import { cn, isNewBrandingActive } from '../rebrand/utilities'

type Props = { className?: string }

const HedepyLogo: React.FC<Props> = ({ className = '' }) => {
  return (
    <Icon
      className={cn('text-secondary', className)}
      icon={isNewBrandingActive ? 'logo-new' : 'logo-blank'}
      size="native"
    />
  )
}

export default HedepyLogo
