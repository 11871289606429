import React from 'react'
import { getTranslate } from '../../utilities/i18n'
import Loader from '../Loader'
import { cn, isNewBrandingActive } from '@/src/rebrand/utilities'

type Props = {
  icon: React.ReactNode
  onClick?: () => void
  innerRef?: React.Ref<HTMLButtonElement>
  isLoading?: boolean
  emphasizeSocials?: boolean
  children: React.ReactNode
}

const GenericLoginButton: React.FC<Props> = ({
  icon,
  onClick,
  children,
  innerRef,
  isLoading = false,
  emphasizeSocials = false,
}) => {
  const { t } = getTranslate()

  return (
    <button
      className={cn(
        `mx-auto flex max-h-10 appearance-none items-center justify-center rounded-md border-[1px] border-solid border-[gainsboro] bg-white px-3 py-3 text-[14px] font-semibold`,
        emphasizeSocials ? 'w-full max-w-[400px]' : '',
        isNewBrandingActive ? 'text-secondary' : 'text-bordeaux',
      )}
      ref={innerRef}
      onClick={onClick}
      disabled={isLoading}
    >
      <div className="pr-4">{icon}</div>
      <div>
        {isLoading ? (
          <div className="-mt-5">
            <Loader visible loaderType="section" />
          </div>
        ) : (
          children || t('component.button.login')
        )}
      </div>
    </button>
  )
}

export default GenericLoginButton
