import GeneralActions from '../redux/GeneralRedux'
import UserActions from '../redux/UserRedux'
import { Tokens, UserType } from '../types/UserStateType'
import { TherapistType } from '../types/TherapistStateType'
import { useDispatch } from 'react-redux'
import { useApis } from '../contexts/api-context'
import { setTokens } from '../utilities/setTokens'
import * as Sentry from '@sentry/nextjs'
import { addGtmEvent } from '@/src/analytics/gtm'
import { FbEventName, sendFbEventByName } from '@/src/analytics/fbPixelData'
import toaster from '@/src/utilities/toaster'

type Data = Tokens & (UserType | TherapistType) & { emailInOtherCountry: string }
type IncomingParams = {
  readonly response: {
    readonly data: Data
    readonly status: number
  }
  readonly email?: string
  readonly redirectUrl?: string
  readonly therapistId?: number
  readonly diagnosis?: string | false
  readonly sendEvent?: boolean
}

/*
 * Use this hook when moving functionality from redux to functional components
 * */
const useProcessSuccessfulLogin = () => {
  const dispatch = useDispatch()
  const apis = useApis()

  return ({
    response,
    redirectUrl,
    email,
    diagnosis = false,
    therapistId = null,
    sendEvent = true,
  }: IncomingParams) => {
    const data = response.data
    if (email) {
      Sentry.setUser({ email })
    }

    const isRegistration = response.status === 201
    if (isRegistration) {
      addGtmEvent('registrationFinished', diagnosis ? 'diagnoseView' : 'dashboardView', { email })
      sendFbEventByName({ eventName: FbEventName.CompleteRegistration, user: { email } })
      toaster.success('component.toaster.registrationSuccess')
    }

    setTokens({
      api: apis.api,
      data: {
        refreshToken: data.refreshToken,
        accessToken: data.accessToken,
      },
    })

    // call necessary actions in reducer for correct behavior
    dispatch(GeneralActions.onChangeGeneralValue('emailIsInOtherCountry', !!data.emailInOtherCountry))
    dispatch(
      UserActions.getUserDataRequest(
        therapistId,
        !!diagnosis,
        sendEvent
          ? {
              event: 'loginSuccessful',
            }
          : {},
        diagnosis,
        redirectUrl,
      ),
    )
  }
}

export default useProcessSuccessfulLogin
