import React from 'react'
import { useRouter } from 'next/router'
import GenericLoginButton from './genericLoginButton'
import { DIAGNOSIS_SESSION_STORAGE_KEY } from '../../constants/storage'
import type { ReduxState } from '../../store/reducers'
import { useSelector } from 'react-redux'

type Props = { isDiagnosis?: boolean; children: React.ReactNode; emphasizeSocials?: boolean }

const FacebookLoginButton: React.FC<Props> = ({ children, isDiagnosis, emphasizeSocials = false }) => {
  const router = useRouter()
  const diagnosis = useSelector((state: ReduxState) => state.diagnosis)

  const handleLogin = async () => {
    const stateParam = JSON.stringify({
      ...router.query,
      doNotCreateNewUser: router.route === '/login' ? true : undefined,
    })

    if (isDiagnosis) {
      sessionStorage.setItem(DIAGNOSIS_SESSION_STORAGE_KEY, JSON.stringify(diagnosis))
    }
    router.push(
      `https://www.facebook.com/v18.0/dialog/oauth?client_id=${
        process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID
      }&redirect_uri=${process.env.NEXT_PUBLIC_FE_URL + '/callback/facebook'}&state=${stateParam}&scope=email`,
    )
  }

  return (
    <>
      <GenericLoginButton
        icon={
          <svg width="18" height="18" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.181 35.87C29.094 34.791 36 27.202 36 18c0-9.941-8.059-18-18-18S0 8.059 0 18c0 8.442 5.811 15.526 13.652 17.471L14 34h5.5l.681 1.87Z"
              fill="#0866FF"
            />
            <path
              d="M13.651 35.471v-11.97H9.936V18h3.715v-2.37c0-6.127 2.772-8.964 8.784-8.964 1.138 0 3.103.223 3.91.446v4.983c-.425-.043-1.167-.065-2.081-.065-2.952 0-4.09 1.116-4.09 4.025V18h5.883l-1.008 5.5h-4.867v12.37a18.183 18.183 0 0 1-6.53-.399Z"
              fill="#FFFFFF"
            />
          </svg>
        }
        onClick={handleLogin}
        emphasizeSocials={emphasizeSocials}
      >
        {children}
      </GenericLoginButton>
    </>
  )
}

export default FacebookLoginButton
